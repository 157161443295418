export var WidgetType;
(function (WidgetType) {
    WidgetType["Sidebar"] = "sidebar";
    WidgetType["Searchbar"] = "searchbar";
    WidgetType["Popup"] = "popup";
})(WidgetType || (WidgetType = {}));
export const WIDGET_NAMES = {
    [WidgetType.Sidebar]: 'brainfish-sidebar-widget',
    [WidgetType.Searchbar]: 'brainfish-searchbar-widget',
    [WidgetType.Popup]: 'brainfish-popup-widget',
};
