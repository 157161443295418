import type { Config } from '@brainfish-ai/widgets-common';
import { sendBrainfishWidgetError } from '../utils/sendBrainfishError';
import { getApiHost, getWidgetHost } from '../utils/getApiHost';
import { createTriggerButton } from './components/TriggerButton';
import { createIframeContainer } from './components/IframeContainer';
import { initMessageListener } from './listeners/initMessageListener';
import { insertStylesIfNeeded } from './utils/insertStyles';
import { initColorSchemeListener } from './listeners/observeColorSchemeChanges';
import { observeColorSchemeChanges } from './listeners/initBodyResizeObserver';
import { base64Encode } from '../utils/base64';
import { IframeStateManager } from './managers/IframeStateManager';
const initializedWidgets = new Set<string>();

function isWidgetInitialized(widgetKey: string): boolean {
  return initializedWidgets.has(widgetKey);
}

function isButtonExisting(widgetKey: string): boolean {
  return !!document.getElementById(`trigger-button-${widgetKey}`);
}

function createWidgetWithButton(
  widgetKey: string,
  widgetUrl: string,
  {
    isButtonHidden,
    triggerButtonIcon,
  }: {
    isButtonHidden: boolean;
    triggerButtonIcon: string;
  }
) {
  const fragment = document.createDocumentFragment();

  if (!isButtonHidden) {
    const { button, icon } = createTriggerButton(widgetKey, triggerButtonIcon);
    fragment.appendChild(button);
    IframeStateManager.initialize({ button, icon });
  } else {
    IframeStateManager.initialize({ isButtonHidden });
  }

  const { iframeContainer, iframe } = createIframeContainer(widgetUrl);
  fragment.appendChild(iframeContainer);

  return { fragment, iframe };
}

function handleInitializationError(
  apiHost: string,
  error: unknown,
  widgetKey: string
) {
  sendBrainfishWidgetError(apiHost, error, (error as Error).message, widgetKey);
}

export const initIframeTrigger = (
  options: {
    widgetKey: string;
    overrides?: any;
  },
  config: Config
): undefined => {
  const apiHost = getApiHost(options.overrides);
  const widgetHost = getWidgetHost(options.overrides);
  const { theme, settings } = config;
  const encodedRules = base64Encode(
    JSON.stringify(options.overrides?.redirectRules)
  );

  // convert theme and settings to hash fragments
  const widgetUrl = `${widgetHost}/?widgetKey=${
    options.widgetKey
  }#${new URLSearchParams({
    theme: base64Encode(JSON.stringify(theme)),
    settings: base64Encode(JSON.stringify(settings)),
    redirectRules: encodedRules,
  }).toString()}`;

  const isButtonHidden = settings?.hideTriggerButton;
  const triggerButtonIcon = settings?.triggerButtonIcon;


  try {
    if (isWidgetInitialized(options.widgetKey)) return undefined;
    if (isButtonExisting(options.widgetKey)) return undefined;

    insertStylesIfNeeded(config);

    const { fragment, iframe } = createWidgetWithButton(
      options.widgetKey,
      widgetUrl,
      {
        isButtonHidden: isButtonHidden || false,
        triggerButtonIcon: triggerButtonIcon || '',
      }
    );

    const controller = new AbortController();
    const { signal } = controller;

    // make sure the iframe is loaded before setting up listeners
    iframe.onload = () => {
      setTimeout(() => {
        // Setup color scheme listener
        initColorSchemeListener(iframe, signal);

        // Setup message listener
        initMessageListener({
          widgetHost,
          apiHost,
          widgetKey: options.widgetKey,
          signal,
        });

        // Setup iframe container resize observer
        observeColorSchemeChanges(iframe, signal);
      }, 0);
    };

    document.body.appendChild(fragment);

    // Store the cleanup function for later use
    initializedWidgets.add(options.widgetKey);

    const cleanup = () => {
      controller.abort(); // Abort the event listener
      window.removeEventListener('beforeunload', cleanup);
    };

    // Add the beforeunload event listener
    window.addEventListener('beforeunload', cleanup);

    return undefined;
  } catch (error) {
    handleInitializationError(apiHost, error, options.widgetKey);
  }
  return undefined;
};