import { IframeStateManager } from '../managers/IframeStateManager';
import { MOBILE_BREAKPOINT } from '../constants/breakpoints';

export function observeColorSchemeChanges(
  iframe: HTMLIFrameElement,
  signal: AbortSignal
) {
  const updateIframeBodyClass = (isMobile: boolean) => {
    const message = { type: 'UPDATE_BODY_CLASS', isMobile };
    iframe.contentWindow?.postMessage(message, '*');

    // if isMobile, and the widget is closed, show the trigger button
    if (isMobile) {
      if (IframeStateManager.isWidgetOpen) {
        IframeStateManager.hideTriggerButton();
      } else {
        IframeStateManager.showTriggerButton();
      }
    }
  };

  let resizeTimeout: number | undefined;

  const checkAndUpdateIsMobile = () => {
    const isMobile = document.body.clientWidth <= MOBILE_BREAKPOINT;
    updateIframeBodyClass(isMobile);
  };

  const debouncedCheckAndUpdateIsMobile = () => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }
    resizeTimeout = window.setTimeout(checkAndUpdateIsMobile, 300);
  };

  const observeBodyResize = () => {
    const resizeObserver = new ResizeObserver(debouncedCheckAndUpdateIsMobile);

    resizeObserver.observe(document.body);

    signal.addEventListener('abort', () => resizeObserver.disconnect());
  };

  // Initial check
  checkAndUpdateIsMobile();

  // Observe size changes on document.body
  observeBodyResize();
}
