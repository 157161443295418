export function initColorSchemeListener(
  iframe: HTMLIFrameElement,
  signal: AbortSignal
) {
  const updateIframeBodyClass = (isDarkMode: boolean) => {
    iframe.contentWindow?.postMessage(
      { type: 'UPDATE_BODY_CLASS', isDarkMode },
      '*'
    );
  };

  const checkAndUpdateDarkMode = () => {
    const isDarkMode = document.body.classList.contains('dark');
    updateIframeBodyClass(isDarkMode);
  };

  const observeBodyClassChanges = () => {
    const observer = new MutationObserver(checkAndUpdateDarkMode);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class'],
    });
    signal.addEventListener('abort', () => observer.disconnect());
  };

  // Initial check
  checkAndUpdateDarkMode();

  // Observe class changes on document.body
  observeBodyClassChanges();
}
