export function base64Encode(text: string): string {
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(text);

  if (typeof window !== 'undefined' && typeof window.btoa === 'function') {
    // Browser environment
    const binaryString = Array.from(uint8Array, (byte) =>
      String.fromCharCode(byte)
    ).join('');
    return btoa(binaryString);
  }

  if (typeof Buffer !== 'undefined') {
    // Node.js environment
    return Buffer.from(uint8Array).toString('base64');
  }

  throw new Error('[utils.base64Encode]: Unsupported environment');
}

export const base64Decode = (text: string): string => {
  if (typeof window !== 'undefined' && typeof window.atob === 'function') {
    // Browser environment
    const binaryString = atob(text);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder();
    return decoder.decode(bytes);
  }

  if (typeof Buffer !== 'undefined') {
    // Node.js environment
    return Buffer.from(text, 'base64').toString('utf-8');
  }

  throw new Error('[utils.base64Decode]: Unsupported environment');
};
