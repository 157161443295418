import { MOBILE_BREAKPOINT } from '../constants/breakpoints';

export class IframeStateManager {
  private static isOpen = false;
  private static button: HTMLElement | null = null;
  private static icon: HTMLElement | null = null;
  private static isButtonHidden = false;

  static get isWidgetOpen() {
    return this.isOpen;
  }

  static initialize({
    button,
    icon,
    isButtonHidden,
  }: {
    button?: HTMLElement;
    icon?: HTMLElement;
    isButtonHidden?: boolean;
  }) {
    this.button = button || null;
    this.icon = icon || null;
    this.isButtonHidden = isButtonHidden || false;

    if (button) {
      button.addEventListener('click', () => this.toggleIframeVisibility());
    }

    // initialize the help widget if it exists
    // DEPRECATED: remove this once customers have migrated to the new Widgets API
    if (window.Brainfish?.HelpWidget) {
      window.Brainfish.HelpWidget.close = () => this.closeWidget();
      window.Brainfish.HelpWidget.open = () => this.openWidget();
    }

    if (window.Brainfish?.Widgets) {
      window.Brainfish.Widgets.open = () => this.openWidget();
      window.Brainfish.Widgets.close = () => this.closeWidget();
    }
  }

  static toggleIframeVisibility() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.openWidget();
    } else {
      this.closeWidget();
    }
  }

  // Open the widget programmatically
  static openWidget() {
    this.isOpen = true;

    const iframeContainer = document.querySelector('.bf-iframe-container');
    const iframe = iframeContainer?.querySelector('iframe');

    iframeContainer?.classList.add('open');
    iframeContainer?.setAttribute('aria-hidden', 'false');
    iframe?.contentWindow?.postMessage({ type: 'FOCUS_SEARCH_FIELD' }, '*');

    // check if the button and icon are visible and set the correct state
    if (this.button && this.icon) {
      const fishIconSVG = this.icon.querySelector('.fish-icon');
      const closeIconSVG = this.icon.querySelector('.close-icon');
      this.button.setAttribute('aria-expanded', 'true');
      fishIconSVG!.classList.remove('visible');
      closeIconSVG!.classList.add('visible');

      // Hide the trigger button if in mobile view
      if (document.body.clientWidth <= MOBILE_BREAKPOINT) {
        this.hideTriggerButton();
      }
    }
    window.dispatchEvent(new Event('onBrainfishWidgetOpen'));
    window.BrainfishAnalytics('track', 'Open Widget');
  }

  // Close the widget programmatically
  static closeWidget() {
    this.isOpen = false;

    const iframeContainer = document.querySelector('.bf-iframe-container');

    iframeContainer?.classList.remove('open');
    iframeContainer?.setAttribute('aria-hidden', 'true');

    // check if the button and icon are visible and set the correct state
    if (this.button && this.icon) {
      this.button.setAttribute('aria-expanded', 'false');
      const fishIconSVG = this.icon.querySelector('.fish-icon');
      const closeIconSVG = this.icon.querySelector('.close-icon');
      fishIconSVG!.classList.add('visible');
      closeIconSVG!.classList.remove('visible');

      // always show the trigger button on close if in mobile view
      if (document.body.clientWidth <= MOBILE_BREAKPOINT) {
        this.showTriggerButton();
      }
    }
    window.dispatchEvent(new Event('onBrainfishWidgetClose'));
    window.BrainfishAnalytics('track', 'Close Widget');
  }

  static showTriggerButton() {
    this.button?.classList.remove('hidden');
  }

  static hideTriggerButton() {
    this.button?.classList.add('hidden');
  }
}
