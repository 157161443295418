import { sendBrainfishWidgetError } from './utils/sendBrainfishError';

// Define interfaces for browser-specific DNT implementations
interface NavigatorWithMS extends Navigator {
  msDoNotTrack?: string;
}

interface WindowWithDNT extends Window {
  doNotTrack?: string;
}

const ANALYTICS_API_HOST = import.meta.env.VITE_ANALYTICS_API_HOST;

let isTrackerInitialized: boolean = false;

const loadTrackerScript = async (apiHost: string, widgetKey: string) => {
  if (document.getElementById('brainfish-analytics')) {
    return window.BrainfishAnalytics;
  }

  const script = document.createElement('script');
  script.id = 'brainfish-analytics';
  script.src = `https://cdn.jsdelivr.net/npm/@brainfish-ai/web-tracker@latest/dist/tracker.js`;
  script.type = 'module';
  script.async = true;
  script.crossOrigin = 'anonymous';

  script.onerror = () => {
    sendBrainfishWidgetError(
      apiHost,
      new Error(`Failed to load script: ${script.src}`),
      `Failed to load script: ${script.src}`,
      widgetKey
    );
  };

  document.head.appendChild(script);
};

/**
 * Checks if Do Not Track (DNT) is enabled across different browsers
 *
 * This checks various browser-specific implementations of DNT:
 * - Standard DNT header (navigator.doNotTrack)
 * - MS IE/Edge (window.doNotTrack)
 * - Old Firefox (navigator.msDoNotTrack)
 *
 * @returns {boolean} true if DNT is enabled, false otherwise
 */
const isDoNotTrackEnabled = (): boolean => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return false;
  }

  // List of possible DNT values that indicate tracking is not allowed
  const dntEnabledValues = ['1', 'yes', 'true'];

  const nav = navigator as NavigatorWithMS;
  const win = window as WindowWithDNT;

  const dnt = (
    nav.doNotTrack || // Standard
    win.doNotTrack || // IE11, Edge
    nav.msDoNotTrack || // IE9, IE10
    '0' // Default to opt-in (false)
  ).toString().toLowerCase();

  return dntEnabledValues.includes(dnt);
};

export const initBrainfishAnalytics = async (
  widgetKey: string,
  accessKey: string,
  apiHost: string,
  analyticsApiHost: string = ANALYTICS_API_HOST,
  enableRecording: boolean = false,
  recordingBlocklist: string[] = [],
  _allowLocalhostRecording?: boolean
): Promise<void> => {
  if (!accessKey) {
    sendBrainfishWidgetError(
      apiHost,
      new Error('Access key is required'),
      'Access key is required',
      widgetKey
    );
  }

  // Only reinitialize if the access key is different
  if (isTrackerInitialized) {
    return;
  }

  try {
    // Check if DNT is enabled and override tracking settings accordingly
    const isDNTEnabled = isDoNotTrackEnabled();

    window.BrainfishAnalytics('init', {
      apiUrl: analyticsApiHost,
      accessKey,
      trackScreenViews: true,
      trackAttributes: !isDNTEnabled,
      trackOutgoingLinks: !isDNTEnabled,
      enableRecording: !isDNTEnabled && enableRecording,
      recordingBlocklist,
      _allowLocalhostRecording,
    });

    isTrackerInitialized = true;

    await loadTrackerScript(apiHost, widgetKey);
  } catch (error) {
    sendBrainfishWidgetError(
      apiHost,
      error,
      'Failed to initialize BrainfishAnalytics',
      widgetKey
    );
  }
};
