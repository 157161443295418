import { type Config } from '@brainfish-ai/widgets-common';
import { HandOffType } from '../../types/brainfish';

export const detectHandoff = (config: Config) => {
  const usesFreshChat = !!window.fcWidget;

  if (usesFreshChat) {
    return HandOffType.FRESHCHAT;
  }
  return null;
};
