import type { Config, Settings } from '@brainfish-ai/widgets-common';

export type Brainfish = {
  Widgets: {
    init: ({
      widgetKey,
      overrides,
    }: {
      widgetKey: string;
      overrides?: Partial<
        Pick<
          Settings,
          | 'nextBestActions'
          | 'bodyActionButtons'
          | 'footerActionButtons'
          | 'enableRecording'
        >
      > & {
        apiHost?: string;
        analyticsApiHost?: string;
        redirectRules?: RedirectRules;
        [key: string]: any;
      };
    }) => Promise<void>;
    open: () => void;
    close: () => void;
  };
  SearchWidget: {
    initStandard: (config: Config) => void;
  };
  HelpWidget: {
    initPopup: (config: Config) => void;
    close: (trigger?: string) => void;
    open: (trigger?: string) => void;
    toggle: (trigger?: string) => void;
  };
};

declare global {
  interface Window {
    Brainfish: Brainfish;
    BrainfishAnalytics: {
      q?: [string, ...any[]][];
      (method: string, ...args: any[]): void;
    };
    __bfCallbacks: Record<
      string,
      (query: string, answer: string, context: any) => void
    >;
    // FreshChat
    fcWidget: {
      hide: () => void;
      on: (event: string, callback: () => void) => void;
    };
  }
}

export type BrainfishWidgetOptions = {
  widgetKey: string;
  overrides?: any;
};

export type RedirectRule = {
  source: string;
  destination: string;
};

export type RedirectRules = Array<RedirectRule>;

export enum HandOffType {
  FRESHCHAT = 'freshchat',
  INTERCOM = 'intercom',
  ZENDESK = 'zendesk',
  HELPSCOUT = 'helpscout',
  // add more as needed
}