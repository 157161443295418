import { sendBrainfishWidgetError } from './sendBrainfishError';

export async function fetchWithErrorHandling(
  apiHost: string,
  endpoint: string,
  widgetKey: string
): Promise<any> {
  const maxRetries = 3;
  const delay = 500; // half second delay
  let attempt = 0;
  let lastError;

  while (attempt < maxRetries) {
    try {
      const response = await fetch(`${apiHost}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': widgetKey,
        },
      });

      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();
      if (!data) {
        throw new Error('Error loading config: empty response');
      }

      return data; // Return data if successful
    } catch (error) {
      lastError = error;
      attempt++;
      if (attempt >= maxRetries) {
        sendBrainfishWidgetError(
          apiHost,
          error,
          'Error fetching config',
          widgetKey
        );
        throw lastError; // Throw the last error after max retries
      }
      await new Promise((resolve) => setTimeout(resolve, delay)); // Throttle with delay
    }
  }
}