import { HandOffType } from '../../types/brainfish';
import { detectHandoff } from './detectHandoff';
import { type Config, WidgetType } from '@brainfish-ai/widgets-common';
import { initFreshChat } from './freshchat';

export const initHandOffs = async (config: Config) => {
  if (config.widgetType === WidgetType.Popup) {
    const handoffType = detectHandoff(config);

    if (handoffType && handoffType === HandOffType.FRESHCHAT) {
      await initFreshChat();
    }
  }
};
