export const getApiHost = (overrides?: { apiHost?: string }): string => {
  return overrides?.apiHost || import.meta.env.VITE_API_HOST;
};

export const getWidgetHost = (overrides?: { widgetHost?: string }): string => {
  return overrides?.widgetHost || import.meta.env.VITE_WIDGET_HOST;
};

export const getAnalyticsApiHost = (overrides?: {
  analyticsApiHost?: string;
}): string => {
  return overrides?.analyticsApiHost || import.meta.env.VITE_ANALYTICS_API_HOST;
};
