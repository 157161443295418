import { IframeStateManager } from '../managers/IframeStateManager';
import { toggleIframeSize } from '../components/IframeContainer';

export function initMessageListener({
  widgetHost,
  apiHost,
  widgetKey,
  signal,
}: {
  widgetHost: string;
  apiHost: string;
  widgetKey: string;
  signal: AbortSignal;
}) {
  const messageHandler = (event: MessageEvent) => {
    if (event.origin !== widgetHost) return;

    const { type } = event.data;
    if (type === 'TOGGLE_WIDGET_SIZE') {
      toggleIframeSize();
    }

    if (type === 'TRACK_EVENT') {
      if (!window.BrainfishAnalytics) return;
      const { eventName, data } = event.data;
      window.BrainfishAnalytics('track', eventName, data);
    }

    if (type === 'CLOSE_WIDGET') {
      window.BrainfishAnalytics('track', 'Close Widget');
      IframeStateManager.toggleIframeVisibility();
    }

    if (type === 'NEXT_BEST_ACTION_CLICKED') {
      const { action, searchQueryId, query, answer } = event.data;
      const context = { action, searchQueryId };

      if (!['function', 'callback'].includes(action.type)) {
        console.error('Invalid action type', action);
        return;
      }

      window.__bfCallbacks = window.__bfCallbacks || {};

      if (window.__bfCallbacks[action.id]) {
        window.__bfCallbacks[action.id](query, answer, context);
        return;
      }
      const scriptUrl = `${apiHost}/api/searchWidgets.callback.codeblocks?apiKey=${widgetKey}&codeblockId=${action.id}`;
      const script = document.createElement('script');
      script.src = scriptUrl;
      document.head.appendChild(script);

      // on script load, call the callback
      script.onload = () => {
        window.__bfCallbacks[action.id](query, answer, context);
        // remove script after 300ms
        setTimeout(() => {
          document.head.removeChild(script);
        }, 300);
      };
    }
  };

  window.addEventListener('message', messageHandler, { signal });
}
