// web.ts
// entry point for web component
import { Config } from '@brainfish-ai/widgets-common';
import { getConfigByKey } from './api/getConfigByKey';
import { initSearchWidget } from './initSearchWidget';
import { initBrainfishAnalytics } from './initBrainfishAnalytics';
import type { Brainfish } from './types/brainfish';
import { BrainfishQueue, createBrainfishQueue } from './utils/brainfishQueue';
import { createQueues } from './utils/createQueues';
import { getApiHost, getAnalyticsApiHost } from './utils/getApiHost';
import { initIframeTrigger } from './initIframeTrigger';
import type { BrainfishWidgetOptions } from './types/brainfish';
import { base64Decode } from './utils/base64';
import { initHandOffs } from './utils/handoffs';

type InternalWidgetOptions = BrainfishWidgetOptions & {
  /** @internal Platform-only configuration override */
  config?: string; // BASE64 encoded string. Used for preview mode on platform.
};

let globalBrainfish: BrainfishQueue | null = null;

function getBrainfishInstance(): BrainfishQueue {
  if (typeof window !== 'undefined' && (window as any).Brainfish) {
    return (window as any).Brainfish;
  }
  if (!globalBrainfish) {
    globalBrainfish = createBrainfishQueue();
    if (typeof window !== 'undefined') {
      (window as any).Brainfish = globalBrainfish;
    }
  }
  return globalBrainfish;
}

const Brainfish = getBrainfishInstance();

let initializationPromise: Promise<void> | null = null;

async function initializeBrainfish(
  options: InternalWidgetOptions,
  config: Config,
  apiHost: string,
  analyticsApiHost: string
): Promise<void> {
  const enableRecording =
    options.overrides?.enableRecording ||
    config?.settings?.enableRecording ||
    false;
  const requestedVersion = options.overrides?.version || config?.version;

  const initPromises: Promise<Brainfish | undefined | void>[] = [];

  if (requestedVersion?.startsWith('2.')) {
    // init new Iframe Search Widget
    initIframeTrigger(options, config);
  } else {
    // init legacy Search Widget
    initPromises.push(initSearchWidget(options, config));
  }

  initPromises.push(
    initBrainfishAnalytics(
      options.widgetKey,
      config.trackingToken || '',
      apiHost,
      analyticsApiHost,
      enableRecording,
      config?.settings?.recordingBlocklist || [],
      (config?.settings as any)?._allowLocalhostRecording
    ) // Even if this fails, it won't block the widget
  );

  // Start both initializations in parallel
  const [widgetPromise] = await Promise.allSettled(initPromises);

  // Only set the real Brainfish if widget initialization succeeded
  if (
    widgetPromise &&
    widgetPromise.status === 'fulfilled' &&
    widgetPromise.value
  ) {
    Brainfish._setRealBrainfish(widgetPromise.value);
  }
}

// Set the widget as not ready
Brainfish.Widgets.isReady = false;

// Identify the user
Brainfish.Widgets.identify = (userData: Record<string, unknown>) => {
  window.BrainfishAnalytics('identify', userData);
};

// Initialize the widget
Brainfish.Widgets.init = async (
  options: InternalWidgetOptions
): Promise<void> => {
  createQueues();

  const apiHost = getApiHost(options.overrides);
  const analyticsApiHost = getAnalyticsApiHost(options.overrides);

  let config: Config;
  if (options.config) {
    config = JSON.parse(base64Decode(options.config)) as Config;
  } else {
    const { config: fetchedConfig } = await getConfigByKey({
      widgetKey: options.widgetKey,
      apiHost,
    });
    config = fetchedConfig;
  }

  if (!initializationPromise) {
    // First initialization
    initializationPromise = initializeBrainfish(
      options,
      config,
      apiHost,
      analyticsApiHost
    );
  }

  // Wait for Brainfish to be initialized
  await initializationPromise;

  await initHandOffs(config);

  // Set the widget as ready
  Brainfish.Widgets.isReady = true;

  // emit event once onReady is called
  window.dispatchEvent(new Event('onBrainfishReady'));
};

export default Brainfish;

export const BrainfishAnalytics = (
  ...args: Parameters<typeof window.BrainfishAnalytics>
) => {
  if (typeof window !== 'undefined' && window.BrainfishAnalytics) {
    window.BrainfishAnalytics(...args);
  }
};
