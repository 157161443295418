import styles from './iframe-widget-styles.css?inline';

import type { Config } from '@brainfish-ai/widgets-common';
import getLuminance from 'polished/lib/color/getLuminance';
import darken from 'polished/lib/color/darken';
import lighten from 'polished/lib/color/lighten';
import { TRIGGER_BUTTON_CLASS } from '../constants/classes';

function insertStyles(
  primaryColor: string = '#000',
  foreground: string = 'FFF'
) {
  const style = document.createElement('style');
  style.attributes.setNamedItem(document.createAttribute('data-widget-styles'));
  const isLightButtonColor = getLuminance(primaryColor) > 0.5;

  style.textContent = `
    ${styles}
    .${TRIGGER_BUTTON_CLASS} { background: ${primaryColor}; }
    .${TRIGGER_BUTTON_CLASS}:hover {
      background: ${
        isLightButtonColor
          ? darken(0.05, primaryColor)
          : lighten(0.05, primaryColor)
      };
    }
    .trigger-button-icon { color: ${foreground}; }
  `;

  document.head.appendChild(style);
}

export function insertStylesIfNeeded(config: Config) {
  if (!document.querySelector('style[data-widget-styles]')) {
    const buttonColor = config?.theme?.inputButtonBgColor || '#007bff';
    const iconColor = config?.theme?.textBaseColor || '#ffffff';
    insertStyles(buttonColor, iconColor);
  }
}
