import { createElementWithClass } from '../../utils/createElementWithClass';
import {
  IFRAME_CONTAINER_CLASS,
  TRIGGER_BUTTON_CLASS,
} from '../constants/classes';

export function createTriggerButton(widgetKey: string, triggerButtonIcon: string): {
  button: HTMLElement;
  icon: HTMLElement;
} {
  const button = createElementWithClass('button', TRIGGER_BUTTON_CLASS, {
    id: `trigger-button-${widgetKey}`,
    'aria-controls': IFRAME_CONTAINER_CLASS,
    'aria-expanded': 'false',
    'data-name': TRIGGER_BUTTON_CLASS,
  });

  const icon = createElementWithClass('div', 'trigger-button-icon', {
    'aria-hidden': 'true',
  });

  if (!triggerButtonIcon) {
    icon.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256" class="close-icon">
      <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
    </svg>
    <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="fish-icon visible">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.71844 10.3882L4.60606 6.98384L1.71844 3.58375C1.4014 3.21362 1.44424 2.65841 1.81269 2.33993C1.97978 2.1979 2.65242 1.98701 3.04657 2.43461L5.78425 5.65824C6.14281 5.29523 6.51693 4.95924 6.90479 4.65114C8.88976 3.07437 11.2345 2.22803 13.6931 2.22803C16.9492 2.22803 20.0039 3.71718 22.2917 6.41142C22.5702 6.74282 22.5702 7.22916 22.2917 7.56056C20.0039 10.2591 16.9492 11.744 13.6931 11.744C11.2348 11.744 8.89035 10.8948 6.90554 9.31995C6.51741 9.01199 6.14304 8.67628 5.78425 8.31374L3.04657 11.5374C2.72953 11.9075 2.18114 11.9505 1.81269 11.632C1.44852 11.3179 1.40568 10.7584 1.71844 10.3882ZM5.93026 10.4683C8.17161 12.2599 10.8546 13.25 13.6931 13.25C17.4881 13.25 21.019 11.4034 23.4447 8.52942C24.1873 7.64571 24.1843 6.31801 23.4397 5.43663C20.8944 2.43919 17.4337 0.722025 13.6931 0.722025C10.8555 0.722025 8.17194 1.70845 5.92952 3.50276L4.17682 1.43933C3.57943 0.760929 2.79325 0.630009 2.25286 0.662947C1.98116 0.679506 1.73125 0.736852 1.51895 0.811846C1.26839 0.900352 1.03017 1.02718 0.827835 1.20058C-0.165283 2.05903 -0.283916 3.561 0.574656 4.56345L2.63075 6.98445L0.568104 9.41623C-0.272133 10.4106 -0.166523 11.9125 0.827835 12.7714C1.81372 13.6243 3.34308 13.5062 4.19036 12.5171L5.93026 10.4683Z" fill="currentColor"/>
    </svg>
  `;
  } else {
    icon.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256" class="close-icon">
        <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
      </svg>
      <img src="${triggerButtonIcon}" class="fish-icon visible" alt="Trigger button icon" height="32" width="32" aria-hidden="true" aria-label="help button icon" />
    `;
  }

  button.setAttribute('aria-label', 'Open Brainfish widget');
  button.appendChild(icon);

  return { button, icon };
}
