import { createElementWithClass } from '../../utils/createElementWithClass';
import { IFRAME_CONTAINER_CLASS } from '../constants/classes';

export function createIframeContainer(widgetUrl: string) {
  const iframeContainer = createElementWithClass(
    'div',
    IFRAME_CONTAINER_CLASS,
    {
      id: IFRAME_CONTAINER_CLASS,
      'aria-live': 'polite',
      'aria-hidden': 'true',
    }
  );

  // Create loading indicator with spinner
  const loadingIndicator = createElementWithClass('div', 'iframe-loading-indicator');
  
  // Create spinner element
  const spinner = createElementWithClass('div', 'spinner');
  
  // Add aria-label for accessibility
  loadingIndicator.setAttribute('aria-label', 'Loading content');
  loadingIndicator.setAttribute('role', 'status');
  
  // Append spinner to loading indicator
  loadingIndicator.appendChild(spinner);
  
  // Append loading indicator to container
  iframeContainer.appendChild(loadingIndicator);

  const iframe = createElementWithClass('iframe', 'trigger-iframe', {
    src: widgetUrl,
    sandbox: 'allow-scripts allow-same-origin allow-popups allow-forms',
    allow: 'clipboard-write',
    role: 'dialog',
  });

  // Hide iframe initially while loading
  iframe.style.opacity = '0';
  
  // Add load event listener to hide loading indicator when iframe loads
  iframe.addEventListener('load', () => {
    loadingIndicator.style.display = 'none';
    iframe.style.opacity = '1';
  });

  // Add error handling
  iframe.addEventListener('error', (error) => {
    console.error('Error loading iframe:', error);
    
    // Replace spinner with error icon
    loadingIndicator.innerHTML = '';
    const errorIcon = createElementWithClass('div', 'loading-error');
    loadingIndicator.appendChild(errorIcon);
    
    // Update aria-label for accessibility
    loadingIndicator.setAttribute('aria-label', 'Failed to load content');
  });

  iframeContainer.appendChild(iframe);

  return { iframeContainer, iframe };
}

export function toggleIframeSize() {
  const iframeContainer = document.querySelector(
    `.${IFRAME_CONTAINER_CLASS}`
  ) as HTMLDivElement;

  if (iframeContainer.classList.contains('expanded')) {
    iframeContainer.classList.remove('expanded');
  } else {
    iframeContainer.classList.add('expanded');
  }
}
